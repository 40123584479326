import { Splide } from '@splidejs/splide';

class GiftGuideCarousel extends HTMLElement {
  constructor() {
    super();
    this.splide = null;
  }
  connectedCallback() {
    this.initializeSplide();
  }
  initializeSplide() {
    this.splide = new Splide(this, {
      mediaQuery: 'min',
      type: 'loop',
      perPage: 2,
      perMove: 1,
      gap: '1rem',
      arrows: true,
      pagination: false,
      classes: {
        arrows: 'splide__arrows !bg-transparent absolute flex w-full mt-3.5 top-1/3',
        arrow: 'splide__arrow !bg-transparent',
        prev: '-left-6 splide__arrow--prev',
        next: '-right-6 splide__arrow--next'
      },
      breakpoints: {
        990: {
          perMove: 1,
          perPage: 5
        }
      }
    });
    this.splide.mount();
  }
}
customElements.define('gift-guide-carousel', GiftGuideCarousel);
